<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div class="pa-4 grey lighten-4">
      <div class="d-flex align-center justify-space-between mb-4">
        <p class="subtitle-1 ma-0">Lista de funcionários</p>
        <v-btn
          v-if="funcionarioImportar"
          small
          depressed
          color="fmq_gray"
          dark
          @click="dialog = !dialog"
          >Incluir novo Usuário</v-btn
        >
        <FuncionarioAdd
          :dialog="dialog"
          :loading="loadingBtn"
          @close="dialog = !dialog"
          @send="addUser"
        />
      </div>
      <FuncionariosTabela
        :headers="headers"
        :funcionarios="funcionarios"
        :total="totalUsers"
        :pageText="pageText"
        :loading="loading"
        :pageNumber="formData.page"
        @handleFilter="filter"
      >
        <template v-slot:nome>
          <v-text-field
            v-model="formData.nome"
            placeholder="Digite o nome"
            solo
            flat
            dense
            :hide-details="true"
            @input="formData.page = 1;getUsers(formData)"
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:[`user.tipo`]>
          <v-autocomplete
            v-model="formData.roleId"
            :items="listRoles"
            hide-no-data
            hide-selected
            item-text="name"
            item-value="id"
            placeholder="Digite a permissão"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="formData.page = 1;getUsers(formData)"
          ></v-autocomplete>
        </template>

        <template v-slot:[`mesa.nome`]>
          <v-autocomplete
            v-model="formData.mesaId"
            :items="listMesas"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Digite a mesa"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="formData.page = 1;getUsers(formData)"
          ></v-autocomplete>
        </template>

        <template v-slot:email>
          <v-text-field
            v-model="formData.email"
            placeholder="Digite o email"
            solo
            flat
            dense
            :hide-details="true"
            @input="formData.page = 1;getUsers(formData)"
            :clearable="true"
          >
          </v-text-field>
        </template>
        <template v-slot:matricula>
          <v-text-field
            v-model="formData.matricula"
            placeholder="Digite a matricula"
            solo
            flat
            dense
            :hide-details="true"
            @input="formData.page = 1;getUsers(formData)"
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:status>
          <v-autocomplete
            v-model="formData.status"
            :items="listStatus"
            hide-no-data
            hide-selected
            item-text="status"
            placeholder="Digite o status"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="formData.page = 1;getUsers(formData)"
          ></v-autocomplete>
        </template>

        <template v-slot:uuid>
          <div class="d-flex justify-start">
            <v-btn class="mr-2" outlined x-small fab color="red" @click="clear">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn outlined x-small fab color="blue" @click="search">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>
      </FuncionariosTabela>
      <AlertSuccess
        :dialog="success"
        dialogMessage="Funcionário adicionado com sucesso"
        @close="success = !success"
      />
      <AlertError :alertError="error" :messageError="messageError" />
    </div>
  </div>
</template>

<script>
import FuncionarioAdd from "@/components/funcionarios/FuncionarioAdd.vue";
import FuncionariosTabela from "@/components/funcionarios/FuncionariosTabela.vue";
import {
  getFuncionarios,
  listPermissoes,
  listMesas,
  importarFuncionario,
} from "@/services/user";
export default {
  name: "Funcionarios",
  components: { FuncionariosTabela, FuncionarioAdd },
  data: () => ({
    formData: {
      orderBy: null,
      orderSorted: null,
      perPage: 15,
      page: 1,
      nome: null,
      roleId: null,
      mesaId: null,
      email: null,
      matricula: null,
      status: null,
    },
    headers: [
      { text: "Funcionário", value: "nome" },
      { text: "Permissão Atual", value: "user.tipo", sortable: false },
      { text: "Mesa", value: "mesa.nome", sortable: false },
      { text: "Email", value: "email" },
      { text: "Matricula", value: "matricula" },
      { text: "Status", value: "status" },
      { text: "Ações", value: "uuid", sortable: false },
    ],
    funcionarios: [],
    totalUsers: null,
    pageText: null,
    loading: false,
    listRoles: [],
    listMesas: [],
    listStatus: ["Ativo", "Inativo"],
    breadcrumbs: [
      {
        text: "Funcionários",
        disabled: true,
        to: "funcionarios",
      },
    ],
    dialog: false,
    error: false,
    success: false,
    messageError: null,
    loadingBtn: false,
  }),
    beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    if (this.$store.state.funcionariosSearch.length) {
      this.formData = { ...this.$store.state.funcionariosSearch[0] };
    }
    this.getUsers(this.formData);
    this.listRole();
    this.listMesa();
  },
  methods: {
    async getUsers(payload) {
      this.funcionarios = [];
      this.$store.dispatch("setFuncionariosSearch", payload);
      try {
        this.loading = true;
        const resp = await getFuncionarios(payload);
        this.funcionarios = resp.data.data;
        this.totalUsers = resp.data.total;
        this.pageText = `${resp.data.from}-${resp.data.to} de ${resp.data.total}`;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async listRole() {
      const resp = await listPermissoes();
      this.listRoles = resp.data;
    },
    async listMesa() {
      const resp = await listMesas();
      this.listMesas = resp.data;
    },
    clear() {
      this.$store.dispatch("setFuncionariosSearch", {});
      this.formData.nome = null;
      this.formData.roleId = null;
      this.formData.mesaId = null;
      this.formData.email = null;
      this.formData.matricula = null;
      this.formData.status = null;
      this.formData.page = 1;
      this.getUsers(this.formData);
    },
    search() {
      this.formData.page = 1;
      this.getUsers(this.formData);
    },
    filter(event) {
      this.$store.dispatch("setFuncionariosSearch", this.formData);
      this.formData.orderBy = event.orderBy;
      this.formData.orderSorted = event.orderSorted;
      this.formData.perPage = event.perPage;
      this.formData.page = event.page;
      this.getUsers(this.formData);
    },
    async addUser(payload) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await importarFuncionario(payload).then(() => {
          this.success = true;
        });
        this.loadingBtn = false;
        this.dialog = false;
      } catch (e) {
        this.dialog = false;
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
  },
  computed: {
    funcionarioImportar() {
      return this.$store.state.user.data.permissoes.includes(
        "funcionario.importar"
      );
    },
  },
};
</script>
